import React, { useContext, useEffect, useRef, useState } from "react";
import {
	Button,
	IconButton,
	TableCell,
	TableRow,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Collapse,
	Tooltip,
	Box,
	Popover,
} from "@mui/material";
import {
	CallIcon,
	DoctorLightIcon,
	MailIcon,
	PatientLightIcon,
	RegionIcon,
	ClockPrimaryIcon,
	CalendarSecondaryIcon,
	PhoneSecondaryIcon,
	StatusCheckSecondaryIcon,
	DoctorPrimaryIcon,
	PatientPrimaryIcon,
	PatientSecondaryIcon,
	PhysicianSecondaryIcon,
	MailSecondaryIcon,
	NotesIcon,
	CopyIcon,
	LocationIcon,
	LocationSecondaryIcon,
} from "assests/images";
import {
	getTypeWiseClassName,
	copyTextOnClipBoard,
	createCommonAPICall,
	openSucessErrorModal,
	showLoader,
	hideLoader,
	startTrackingProvider,
	isMobile,
	isIOS,
} from "utility/helpers";
import { SendEmailModal } from "./send-email-modal";
import { BlockCaseModal } from "./block-case-modal";
import { AssignTransferCaseModal } from "./assign-transfer-case-modal";
import { CancelCaseModal } from "./cancel-case-modal";
import {
	IRequest,
	IGridButtons,
	IGridColumns,
	IDashboardSearchCriteria,
} from "utility/interfaces";
import {
	WarningModal,
	CustomCopyTooltip,
	DashboardTableRowMenu,
	SendDocumetModal,
	WhatsAppChatBox,
	ExpandMore,
	ViewMoreModal,
} from "components";
import { SendAgreementModal } from "./send-agreement-modal";
import { SendDefaultMessageModal } from "./send-default-message-modal";
import {
	AccountType,
	RequestStatus,
	InformationTabTypes,
	RequestTypes,
} from "utility/enums/request-status";
import { AcceptCaseModal } from "./accept-case";
import { Constants } from "utility/enums/constants";
import { useNavigate } from "react-router-dom";
import { AppRoutings } from "utility/enums/app-routings";
import { getTabStatusWiseName } from "utility/helpers";
import { UserContext } from "contexts/user-context";
import { SelectCallTypeModal } from "./select-call-type-modal";
import { AlreadyFinalizedModal } from "./already-finalized-modal";
import { CLOUD_URL } from "config";
import dashboardService from "services/dashboard-service";
import { SuccessErrorModalDispatchContext } from "contexts/success-error-context";
import { getTypeNameByTypeId } from "utility/helpers/dashboard";
import Geocode from "react-geocode";
import tokenManager from "utility/auth-guards/token-manager";
import moment from "moment";
import {
	PhysicianTrackContext,
	PhysicianTrackDispatchContext,
} from "contexts/physician-track-context";
import { ITrackContextInfo } from "utility/interfaces/common/track-context-info";
import { HubConnection, HubConnectionBuilder } from "@microsoft/signalr";

type ComponentProps = {
	row: IRequest;
	isSmallDevice: boolean;
	handleOpenChatBox: any;
	gridButtonsPermissions: IGridButtons;
	gridColumnPermissions: IGridColumns;
	searchCriteria: IDashboardSearchCriteria;
	getDashboardDetails: any;
	toShowDob: boolean;
};

export function DashboardTableRowData(props: ComponentProps) {
	const setSuccessErrorContext = useContext(SuccessErrorModalDispatchContext);
	const userRole = useContext(UserContext);
	const TrackingIds = useContext(PhysicianTrackContext);
	const setTrackingIds = useContext(PhysicianTrackDispatchContext);

	// useStates
	const [tableUser, setTableUser] = useState<null | HTMLElement>(null);
	const [expanded, setExpanded] = useState(false);
	const [sendMailOpen, setSendMailOpen] = useState(false);
	const [blockCaseModelOpen, setBlockCaseModelOpen] = useState(false);
	const [assignCaseModalOpen, setAssignCaseModalOpen] = useState(false);
	const [transferCaseModalOpen, setTransferCaseModalOpen] = useState(false);
	const [accpetCaseModalOpen, setAccpetCaseModalOpen] = useState(false);
	const [cancelCaseModalOpen, setCancelCaseModalOpen] = useState(false);
	const [clearCaseModalOpen, setClearCaseModalOpen] = useState(false);
	const [sendAgreementModalOpen, setSendAgreementModalOpen] = useState(false);
	const [selectCallTypeModalOpen, setSelectTypeModalOpen] = useState(false);
	const [alreadyFinalizedModalOpen, setAlreadyFinalizedModalOpen] =
		useState(false);
	const [sendDocumentModalOpen, setSendDocumentModalOpen] = useState(false);
	const [
		sendRecommendationEmailModalOpen,
		setSendRecommendationEmailModalOpen,
	] = useState(false);
	const [regenerateConfirmationModalOpen, setRegenerateConfirmationModalOpen] =
		useState(false);
	const [sendDefaultMessageModalOpen, setSendDefaultMessageModalOpen] =
		useState(false);

	const [email, setEmail] = useState(props.row.email);
	const [docTypeValues, setDocTypeValues] = useState({
		title: "",
		message: "",
		docType: 0,
	});

	// const [isPageHidden, setIsPageHidden] = useState(false);
	// state variable does not have latest value in setTimout function, so changed isPageHidden from state to ref
	const isPageHidden = useRef(false);

	const [ringCentral, setringCentral] = useState<null | HTMLCollection>(null);
	const navigate = useNavigate();
	const [rejectCase, setRejectCase] = useState("Cancel Case");

	// For WhatsApp popover
	const [isWhatsAppPopoverOpen, setIsWhatsAppPopoverOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
	const [chatInitiateTime, setChatInitiateTime] = useState("");
	const [isTransferNotesModalOpen, setIsTransferNotesModalOpen] =
		useState(false);
	const [isAppOpenInfoModalOpen, setIsAppOpenInfoModalOpen] = useState(false);
	const [locationHubConnection, setLocationHubConnection] =
		useState<null | HubConnection>(null);

	Geocode.setApiKey(Constants.GeocodeKey);

	// Extract Props
	const {
		row,
		isSmallDevice,
		handleOpenChatBox,
		gridButtonsPermissions,
		gridColumnPermissions,
		searchCriteria,
		getDashboardDetails,
		toShowDob,
	} = props;

	// Handled events and functions
	const handleTableMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setTableUser(event.currentTarget);
	};
	const handleTableMenuClose = () => {
		setTableUser(null);
	};
	const handleExpandClick = () => {
		setExpanded(!expanded);
	};
	const handleSendMailOpen = () => setSendMailOpen(true);
	const handleSendEmailModalClose = () => setSendMailOpen(false);
	const handleCancelCaseModalOpen = () => {
		setCancelCaseModalOpen(true);
		handleTableMenuClose();
	};
	const handleBlockCaseModalOpen = () => {
		setBlockCaseModelOpen(true);
		handleTableMenuClose();
	};
	const handleAssignCaseModalOpen = () => {
		setAssignCaseModalOpen(true);
		handleTableMenuClose();
	};
	const handleTransferCaseModalOpen = () => {
		setTransferCaseModalOpen(true);
		handleTableMenuClose();
	};
	const handleAcceptCaseModalOpen = () => {
		setAccpetCaseModalOpen(true);
		handleTableMenuClose();
	};
	const handleTransferNotesModalOpen = () => setIsTransferNotesModalOpen(true);
	const handleTransferNotesModalClose = () =>
		setIsTransferNotesModalOpen(false);
	const handleBlockCaseModalClose = (isSuccess?: boolean) => {
		if (isSuccess) getDashboardDetails();
		setBlockCaseModelOpen(false);
		handleTableMenuClose();
	};
	const assignCaseModalClose = (isSuccess?: boolean) => {
		if (isSuccess) getDashboardDetails();
		setAssignCaseModalOpen(false);
		handleTableMenuClose();
	};
	const transferCaseModalClose = (isSuccess?: boolean) => {
		if (isSuccess) getDashboardDetails();
		setTransferCaseModalOpen(false);
		handleTableMenuClose();
	};
	const handleAccpetCaseModalClose = (isSuccess?: boolean) => {
		if (isSuccess) getDashboardDetails();
		setAccpetCaseModalOpen(false);
		handleTableMenuClose();
	};
	const cancelCaseModalClose = (isSuccess?: boolean) => {
		console.log(isSuccess);
		if (isSuccess) getDashboardDetails();
		setCancelCaseModalOpen(false);
		handleTableMenuClose();
	};
	const handleOnClickCloseClearCase = () => {
		setClearCaseModalOpen(false);
		handleTableMenuClose();
	};
	const sendAgreementModalClose = () => {
		setSendAgreementModalOpen(false);
		handleTableMenuClose();
	};
	const handleClearCaseModalOpen = () => {
		setClearCaseModalOpen(true);
		handleTableMenuClose();
	};
	const handleSendAgreemenrModalOpen = () => {
		setSendAgreementModalOpen(true);
		handleTableMenuClose();
	};
	const handleOnClickAcceptCase = () => {
		// TODO: clear case logic here
		setAccpetCaseModalOpen(false);
		handleTableMenuClose();
	};
	const handleOnClickClearCase = () => {
		// TODO: clear case logic here
		const requestBody = {
			requestId: row.requestId,
			status: RequestStatus.Clear,
		};

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.clearCase,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				setClearCaseModalOpen(false);
				handleTableMenuClose();
				getDashboardDetails();
			}
		});
	};

	const handleOnClickSendAgreement = (values: any) => {
		const requestBody = {
			requestId: row.requestId,
			...values,
		};
		console.log(requestBody);

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.sendAgreement,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				setSendAgreementModalOpen(false);
				handleTableMenuClose();
				getDashboardDetails();
			}
		});
	};

	const handleOnClickCopy = (e: any, text: string) => {
		console.log(text);
		e.stopPropagation();
		copyTextOnClipBoard(text);
	};

	const handleSelectCallTypeOpen = () => {
		setSelectTypeModalOpen(true);
		handleTableMenuClose();
	};
	const handleSelectCallTypeClose = () => {
		setSelectTypeModalOpen(false);
		handleTableMenuClose();
	};

	const handleConnectHub = () => {
		const newConnection = new HubConnectionBuilder()
			.withUrl(Constants.LocationHubUrl)
			.withAutomaticReconnect()
			.build();

		setLocationHubConnection(newConnection);
	};

	const handleTrackSuccess = (position: GeolocationPosition) => {
		console.log("Tracking...", position, row.queryString, row.physicianId);
		locationHubConnection?.send(
			"UpdatePhysicianLocation",
			position.coords.latitude,
			position.coords.longitude,
			row.queryString,
			row.physicianId
		);
	};

	const handleOnClickCallType = async (
		formValues: any,
		latitude: number | null,
		longitude: number | null
	) => {
		const requestBody = {
			requestId: row.queryString,
			...formValues,
			latitude,
			longitude,
		};
		if (requestBody.isHouseCallOrConsult == 1) {
			console.log("Before ", TrackingIds);
			let id = startTrackingProvider(handleTrackSuccess);
			TrackingIds.push({
				requestId: row.queryString,
				trackId: id,
			});

			setTrackingIds(TrackingIds);

			console.log("After ", TrackingIds);
		}
		console.log(requestBody);
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.saveCallTypeForRequest,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			handleSelectCallTypeClose();
			if (data.isSuccessfull) {
				getDashboardDetails();
			}
		});
	};

	const handleEndHouseCall = () => {
		const requestBody = {
			requestId: row.queryString,
			isHouseCallOrConsult: 0,
			hours: 0.5,
		};
		console.log(requestBody);
		let index = TrackingIds.findIndex(
			(t: ITrackContextInfo) => t.requestId === row.queryString
		);
		if (index !== -1) {
			let trackId = TrackingIds[index].trackId;
			navigator.geolocation.clearWatch(trackId);
			TrackingIds.splice(index, 1);
			setTrackingIds(TrackingIds);
		}
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.saveCallTypeForRequest,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			handleSelectCallTypeClose();
			if (data.isSuccessfull) {
				getDashboardDetails();
			}
		});
	};

	const handleAlreadyFinalizedModalOpen = (docType: number) => {
		console.log(docType);
		switch (docType) {
			case 1:
				setDocTypeValues({
					title: "Encounter Form",
					message: "Encounter form is finalized successfully!",
					docType: 1,
				});
				break;
			case 2:
				setDocTypeValues({
					title: "Recommendation Form",
					message: "Recommendation form is finalized successfully!",
					docType: 2,
				});
				break;
		}
		setAlreadyFinalizedModalOpen(true);
		handleTableMenuClose();
	};
	const handleAlreadyFinalizedModalClose = () => {
		setAlreadyFinalizedModalOpen(false);
		handleTableMenuClose();
	};
	const handleOnClickDownload = (docType: number) => {
		let fileUrl = `${CLOUD_URL}`;
		switch (docType) {
			case 1:
				// row.finalizedEncounterFileName =
				// 	row.finalizedEncounterFileName.replaceAll("/", "%2F");
				// fileUrl = `${fileUrl}${row.finalizedEncounterFileName}?alt=media`;
				fileUrl = row.finalizedEncounterFileName;
				break;
			case 2:
				// row.finalizedRecommendationFormName =
				// 	row.finalizedRecommendationFormName.replaceAll("/", "%2F");
				// fileUrl = `${fileUrl}${row.finalizedRecommendationFormName}?alt=media`;
				fileUrl = row.finalizedRecommendationFormName;
				break;
		}
		console.log(fileUrl);
		let link = document.createElement("a");
		link.href = fileUrl;
		link.target = "_blank";
		document.getElementById("downloadBtn")?.appendChild(link);
		link.click();
		link.parentNode?.removeChild(link);
	};
	const handleEncounterSendEmail = (docType: number) => {
		handleSendRecommedationModalOpen();
	};

	const handleSendRecommedationModalOpen = () => {
		setSendRecommendationEmailModalOpen(true);
	};
	const handleSendRecommedationModalClose = () => {
		setSendRecommendationEmailModalOpen(false);
		handleAlreadyFinalizedModalOpen(2);
	};
	const handleOnClickSendRecommedation = () => {
		let fileName =
			row.finalizedRecommendationFormName.split("/")[
				row.finalizedRecommendationFormName.split("/").length - 1
			];
		const requestBody = {
			Id: row.queryString,
			Email: email,
			FileName: fileName,
		};
		createCommonAPICall({
			requestBody,
			apiService: dashboardService.sendMailForRecommendationForm,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			setSendRecommendationEmailModalOpen(false);
		});
	};

	const handleClickRegenerate = () => {
		console.log("Regenerate form");
		setRegenerateConfirmationModalOpen(true);
	};

	const handleCloseRegerateModal = () => {
		setRegenerateConfirmationModalOpen(false);
	};

	const handleConfirmRegenerate = () => {
		const requestBody = {
			id: row.queryString,
			fileName: row.finalizedRecommendationFormName,
		};

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.regenerateRecommendation,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		}).then((data) => {
			console.log(data);
			if (data.isSuccessfull) {
				handleAlreadyFinalizedModalClose();
				navigate(AppRoutings.DoctorsNote.replace(":caseId", row.queryString), {
					state: {
						searchCriteria,
						backURL: AppRoutings.Dashboard.replace(
							":tabStatus",
							getTabStatusWiseName(
								searchCriteria?.RequestStatusId
							).toLocaleLowerCase()
						),
					},
				});
			}
		});
	};

	const handleClickSkipAgreement = async () => {
		console.log("Skip agreement called.", row.queryString);
		const requestBody = {
			id: row.queryString,
		};

		const data = await createCommonAPICall({
			requestBody,
			apiService: dashboardService.skipAgreement,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			console.log(data);
			getDashboardDetails();
			handleTableMenuClose();
		}
	};

	const handleSendDefaultNotificationModalOpen = () => {
		setSendDefaultMessageModalOpen(true);
		handleTableMenuClose();
	};

	const SendDefaultMessageModalClose = () => {
		setSendDefaultMessageModalOpen(false);
		handleTableMenuClose();
	};

	const handleOnClickSendMessage = (values: any) => {
		const requestBody = {
			requestId: row.requestId,
			...values,
		};
		console.log(requestBody);

		createCommonAPICall({
			requestBody,
			apiService: dashboardService.sendDefaultMessage,
			showErrorMessage: true,
			showSuccessMessage: true,
			setSuccessErrorContext,
		}).then((data) => {
			if (data.isSuccessfull) {
				setSendDefaultMessageModalOpen(false);
				handleTableMenuClose();
				getDashboardDetails();
			}
		});
	};

	useEffect(() => {
		var script = document.createElement("script");
		script.setAttribute("id", "ringCentral");
		script.src = Constants.RingCentralScript;
		var rcs0 = document.getElementsByTagName("script")[0];
		rcs0.parentNode!.insertBefore(script, rcs0);

		document.body.appendChild(script);

		const test = document.getElementsByClassName("Adapter_right");
		if (test) {
			setringCentral(test);
		}

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	useEffect(() => {
		if (gridButtonsPermissions.isReject) {
			setRejectCase("Reject");
		}
		if (
			userRole.userRoleId === AccountType.Physician &&
			searchCriteria.RequestStatusId === InformationTabTypes.Active
		) {
			handleConnectHub();
		}
	}, []);

	useEffect(() => {
		if (locationHubConnection) {
			locationHubConnection.start().then((result) => {
				console.log("Connected");
			});
		}
	}, [locationHubConnection]);

	const handleVisibiltyChange = () => {
		if (document.visibilityState === "hidden") {
			hideLoader();
			isPageHidden.current = true;
			// setIsPageHidden(true);
		}
	};

	const handleOnCall = (phone: string) => {
		console.log("call", ringCentral!);

		if (isMobile()) {
			console.log("Mobile device detected");
			try {
				var w = window.parent ? window.parent : window;
				showLoader();
				w.location.assign("rcapp://r/dialer?number=" + phone);
				document.addEventListener("visibilitychange", handleVisibiltyChange);
				setTimeout(() => {
					hideLoader();
					if (!isPageHidden.current) {
						setIsAppOpenInfoModalOpen(true);
					}
					document.removeEventListener(
						"visibilitychange",
						handleVisibiltyChange
					);
					isPageHidden.current = false;
				}, 5000);
				// w.location.assign("rcmobile://call?number=" + row.phone);
			} catch (e) {
				openSucessErrorModal(
					setSuccessErrorContext,
					"Error in calling",
					"Something went wrong for calling",
					false
				);
			}
		} else {
			console.log("Desktop device detected");
			document.getElementById("rc-widget")!.style.display = "block";
			const btn1 = document.querySelector(".Adapter_button.Adapter_popup");
			const btn2 = document.querySelector(".Adapter_button.Adapter_toggle");
			btn1?.addEventListener("click", () => {
				const popup = document.querySelector(".Adapter_right");
				if (popup!.classList.contains("Adapter_minimized")) {
					popup!.setAttribute("style", "display : none");
				} else {
					popup!.setAttribute("style", "display : block");
				}
			});
			btn2?.addEventListener("click", () => {
				const popup = document.querySelector(".Adapter_right");
				if (popup!.classList.contains("Adapter_minimized")) {
					popup!.setAttribute("style", "display : none");
				} else {
					popup!.setAttribute("style", "display : block");
				}
			});
		}
	};

	const handleGetLoaction = (address: string) => {
		var url = "https://www.google.com/maps/search/?api=1&query=" + address + "";
		// var url = "http://www.google.com/maps?q=" + address + "";
		window.open(url, "_blank");
	};

	const handleClickWhatsApp = async (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		const btn = event.currentTarget;
		if (chatInitiateTime !== "" || (await initiateWhatsAppChat())) {
			setAnchorEl(btn);
			setIsWhatsAppPopoverOpen(true);
		} else {
			setAnchorEl(null);
			setIsWhatsAppPopoverOpen(false);
		}
	};

	const initiateWhatsAppChat = async () => {
		const requestBody = {
			requestId: row.requestId,
			// phoneNumber,
			phoneNumber: "(91820) 069-9203", // Change number when business number is configured
			email: "abc",
		};
		console.log(requestBody);
		const data = await createCommonAPICall({
			requestBody,
			apiService: dashboardService.initiateChat,
			showErrorMessage: true,
			showSuccessMessage: false,
			setSuccessErrorContext,
		});

		if (data && data.isSuccessfull) {
			setChatInitiateTime(data.data.time);
			return true;
		}
		return false;
	};

	// Page level variables
	const tableMenuOpen = Boolean(tableUser);

	return (
		<>
			{!isSmallDevice ? (
				<>
					<TableRow
						key={row.requestId}
						className={
							row.declineBy && row.requestStatusId === RequestStatus.Unassigned
								? "bg-declined"
								: getTypeWiseClassName(row.requestTypeId)
						}
					>
						{gridColumnPermissions?.isDisplayName && (
							<TableCell component="th" className="wrap min-w-190" scope="row">
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.name}
								>
									<a
										className="patient-background"
										href={`http://www.google.com/search?q=${row.name} ${row.phone}`}
										target="_blank"
										rel="noreferrer"
									>
										{row.name}
									</a>
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayEmail && (
							<TableCell component="th" scope="row" className="wrap">
								<CustomCopyTooltip text="Send Email">
									<div className="cell-with-icons">
										<IconButton
											className="mail-btn"
											onClick={handleSendMailOpen}
										>
											<img src={MailIcon} alt="email" />
										</IconButton>
									</div>
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayDateOfBirth && toShowDob && (
							<TableCell
								component="th"
								scope="row"
								className="wrap min-w-145 max-w-145"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.dateOfBirth}
								>
									{row.dateOfBirth}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayRegion && (
							<TableCell
								component="th"
								scope="row"
								className="wrap min-w-165 max-w-165"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.regionName}
								>
									{row.regionName}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{/* {gridColumnPermissions?.isDisplayWaitTime && (
							<TableCell
								component="th"
								scope="row"
								className="wrap min-w-100 max-w-100"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.waitTime}
								>
									{row.waitTime}
								</CustomCopyTooltip>
							</TableCell>
						)} */}
						{gridColumnPermissions?.isDisplayRequestorName && (
							<TableCell
								style={{ maxWidth: 140 }}
								component="th"
								scope="row"
								className="wrap min-w-140"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.requestor}
								>
									{row.declineBy != null &&
									row.requestStatusId == RequestStatus.Unassigned
										? "Declined by: " + row.declineBy + row.requestor
										: row.requestor}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayRequestedDate && (
							<TableCell
								style={{ maxWidth: 140 }}
								className="wrap min-w-140"
								component="th"
								scope="row"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={
										moment(new Date(row.requestedDate)).format("MMM D, YYYY") +
										" " +
										row.waitTime
									}
								>
									{moment(new Date(row.requestedDate)).format("MMM D, YYYY") +
										" " +
										row.waitTime}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayPhysicianName && (
							<TableCell
								style={{ maxWidth: 140 }}
								className="wrap min-w-140"
								component="th"
								scope="row"
							>
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.physicianName}
								>
									{row.physicianName}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{/* Date of service */}
						{gridColumnPermissions?.isDisplayAcceptedDate && (
							<TableCell
								style={{ maxWidth: 140 }}
								component="th"
								className="wrap min-w-140"
								scope="row"
							>
								{row.requestStatusId == RequestStatus.Cancelled ||
								row.requestStatusId == RequestStatus.CancelledByPatient ||
								row.requestStatusId == RequestStatus.CancelledByProvider ? (
									<CustomCopyTooltip
										canDoCopy={true}
										text="Copy"
										copyText={row.requestedDate}
									>
										{row.requestedDate}
									</CustomCopyTooltip>
								) : (
									<CustomCopyTooltip
										canDoCopy={true}
										text="Copy"
										copyText={row.acceptedDate}
									>
										{row.acceptedDate}
									</CustomCopyTooltip>
								)}
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayPhone && (
							<TableCell
								style={{ maxWidth: 200 }}
								component="th"
								className="min-w-200"
								scope="row"
							>
								<div className="phone-numbers">
									{row.phone && row.phone?.trim() !== "" ? (
										<>
											{/* href={`tel:${row.requestorPhone}`} */}
											<Tooltip title="Call (Patient)">
												<Button
													variant="outlined"
													onClick={() => handleOnCall(row.phone)}
												>
													<img src={CallIcon} alt="call" />
													{row.phone}
												</Button>
											</Tooltip>
											(Patient)
										</>
									) : null}
									{row.requestorPhone &&
									row.requestTypeId !== RequestTypes.Patient &&
									row.requestorPhone?.trim() !== "" ? (
										<>
											<Tooltip
												title={
													"Call " +
													`(${getTypeNameByTypeId(row.requestTypeId)})`
												}
											>
												<Button
													variant="outlined"
													onClick={() => handleOnCall(row.requestorPhone)}
												>
													<img src={CallIcon} alt="call" />
													{row.requestorPhone}
												</Button>
											</Tooltip>
											({getTypeNameByTypeId(row.requestTypeId)})
										</>
									) : null}
								</div>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayAddress && (
							<TableCell className="wrap" component="th" scope="row">
								<CustomCopyTooltip
									canDoCopy={true}
									text="Copy"
									copyText={row.address}
								>
									{row.address}
								</CustomCopyTooltip>
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayRequestStatus && (
							<TableCell
								style={{ maxWidth: 110 }}
								component="th"
								className="wrap min-w-110"
								scope="row"
							>
								{row.requestStatusId == RequestStatus.Unassigned &&
								row.physicianName != "-" ? (
									row.physicianName
								) : userRole.userRoleId == AccountType.Physician ? (
									row.requestStatusId == RequestStatus.MDOnSite ? (
										<Button variant="contained" onClick={handleEndHouseCall}>
											House Call
										</Button>
									) : (
										"-"
									)
								) : (
									row.requestStatus
								)}
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayTransferNotes && (
							<TableCell
								style={{ maxWidth: 200 }}
								component="th"
								className="wrap min-w-200"
								scope="row"
							>
								{row.transferNotes !== "" ? (
									row.transferNotes.split("\n").length > 2 ? (
										<Tooltip title="Click to view more">
											<div
												style={{ cursor: "pointer" }}
												onClick={handleTransferNotesModalOpen}
											>
												{row.transferNotes.split("\n")[0]} ...
											</div>
										</Tooltip>
									) : (
										<>{row.transferNotes}</>
									)
								) : (
									"-"
								)}
							</TableCell>
						)}
						{gridColumnPermissions?.isDisplayChatWithAdmin ||
						gridColumnPermissions?.isDisplayChatWithPatient ||
						gridColumnPermissions?.isDisplayChatWithPhysician ? (
							<TableCell component="th" scope="row">
								<span className="table-cell-title">Chat With</span>
								<Box
									className="table-cell-btn-group"
									sx={{
										display: { laptop: "flex", xl: "block" },
										flexWrap: "wrap",
										gap: "5px 13px",
									}}
								>
									{row.requestStatusId !== RequestStatus.Unassigned &&
										gridColumnPermissions?.isDisplayChatWithPatient && (
											<Button
												variant="outlined"
												className="btn-light"
												onClick={() =>
													handleOpenChatBox(
														row.userId,
														row.requestId,
														row.physicianId,
														row.physicianName,
														"Admin",
														row.confirmationNumber,
														true
													)
												}
											>
												<img src={PatientLightIcon} alt="" />
												Patient
											</Button>
										)}
									{gridColumnPermissions?.isDisplayChatWithPhysician && (
										<Button
											disabled={!row.physicianId}
											variant="outlined"
											className="btn-light"
											onClick={() =>
												handleOpenChatBox(
													row.userId,
													row.requestId,
													row.physicianId,
													row.physicianName,
													"Admin",
													row.confirmationNumber,
													false
												)
											}
										>
											<img src={DoctorLightIcon} alt="" />
											Provider
										</Button>
									)}
									{gridColumnPermissions?.isDisplayChatWithAdmin && (
										<Button
											disabled={!row.physicianId}
											variant="outlined"
											className="btn-light"
											onClick={() =>
												handleOpenChatBox(
													row.userId,
													row.requestId,
													row.physicianId,
													row.physicianName,
													"Admin",
													row.confirmationNumber,
													false,
													true
												)
											}
										>
											<img src={DoctorLightIcon} alt="" />
											Admin
										</Button>
									)}
									{/* {row.requestStatusId !== RequestStatus.Unassigned &&
										row.requestStatusId !== RequestStatus.Accepted &&
										userRole.userRoleId === AccountType.Admin && (
											<Tooltip title="Chat on WhastApp">
												<Button
													variant="outlined"
													className="btn-light"
													onClick={handleClickWhatsApp}
												>
													<WhatsApp />
												</Button>
											</Tooltip>
										)} */}
								</Box>
							</TableCell>
						) : null}
						<TableCell align="center" component="th" scope="row">
							<span className="table-cell-title">Actions</span>
							<Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn"
							>
								Actions
							</Button>
						</TableCell>
					</TableRow>
				</>
			) : (
				// Mobile view
				// <Card className={getTypeWiseClassName(row.requestTypeId)}> //white background card changes - 09 June 2023
				<Card className="bg-white">
					<div className="card-container">
						<ExpandMore
							expand={expanded}
							onClick={handleExpandClick}
							aria-expanded={expanded}
							aria-label="show more"
						>
							{/* <Button
								id="table-button"
								aria-controls={tableMenuOpen ? "table-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={tableMenuOpen ? "true" : undefined}
								onClick={handleTableMenu}
								className="action-btn"
							>
								Actions
							</Button> */}
							{/* <IconButton className="mail-btn" onClick={handleSendMailOpen}>
								<img src={MailIcon} alt="mail" />
							</IconButton> */}
							<div className="status-container">
								{getTypeNameByTypeId(row.requestTypeId)}
								<Box
									component="span"
									className={`btn-card-status ${getTypeWiseClassName(
										row.requestTypeId
									)}`}
								/>
							</div>
							{gridColumnPermissions?.isDisplayName && (
								<CardHeader
									title={
										<React.Fragment>
											<span>{row.name}</span>
											{
												//row.name
												//</React.Fragment>&& (
												// <IconButton
												// 	className="copy-btn"
												// 	onClick={(e) => handleOnClickCopy(e, row.name)}
												// >
												// 	<img src={CopyIcon} alt="copy" />
												// </IconButton>
												//)
											}
										</React.Fragment>
									}
								/>
							)}
							<CardContent className="card-header-content">
								<div className="card-subheader">
									<div>
										{row.declineBy != null &&
											row.requestStatusId == RequestStatus.Unassigned && (
												<Typography variant="body2" color="text.secondary">
													{"Declined by: " + row.declineBy}
													{/* {row.address && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.address)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
												</Typography>
											)}
										{gridColumnPermissions.isDisplayWaitTime && (
											<Typography>
												{/* <img src={ClockPrimaryIcon} alt="time" /> */}
												Wait Time:&nbsp;
												<div className="dashboard-mobile-waittime">
													{gridColumnPermissions.isDisplayRequestedDate && (
														<span>{row?.requestedDate}</span>
													)}
													<span>
														&nbsp;(
														{row?.waitTime?.replace("h ", ":").replace("m", "")}
														)&nbsp;
													</span>
												</div>
												{/* {row.waitTime && (
											<IconButton
												className="copy-btn"
												onClick={(e) => handleOnClickCopy(e, row.waitTime)}
											>
												<img src={CopyIcon} alt="copy" />
											</IconButton>
										)} */}
											</Typography>
										)}
										{/* {gridColumnPermissions?.isDisplayAddress && (
											<Typography variant="body2" color="text.secondary">
												{row.address}
												{row.address && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.address)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)}
											</Typography>
										)} */}
									</div>
									<ExpandMore
										expand={expanded}
										aria-expanded={expanded}
										aria-label="show more"
										onClick={() => handleGetLoaction(row.mapAddress)}
									>
										Map Location
									</ExpandMore>
								</div>
							</CardContent>
						</ExpandMore>
						<Collapse in={expanded} timeout="auto" unmountOnExit>
							<CardContent>
								{gridColumnPermissions.isDisplayDateOfBirth && (
									<div>
										<span>
											<img src={CalendarSecondaryIcon} alt="calender" />
										</span>
										<Typography variant="h6">
											Date of birth: &nbsp;
											<span>{row.dateOfBirth}</span>
											{/* {row.dateOfBirth && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.dateOfBirth)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayEmail ? (
									<div onClick={handleSendMailOpen}>
										<span>
											<img src={MailSecondaryIcon} alt="email" />
										</span>
										<Typography variant="h6">
											Email: &nbsp;
											<span>{row.email}</span>
											{/* {row.email && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.email)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								) : null}
								{/* {row.address.includes("Room") ? ( */}
								<div>
									<span>
										<img src={LocationSecondaryIcon} alt="email" />
									</span>
									<Typography variant="h6">
										Address: &nbsp;
										<span>{row.mapAddress}</span>
										{/* {row.email && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.email)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
									</Typography>
								</div>
								{/* ) : null} */}
								{row.address.includes("Room") ? (
									<div>
										<span>
											<img src={LocationSecondaryIcon} alt="email" />
										</span>
										<Typography variant="h6">
											Room Location: &nbsp;
											<span>{row.address.replace("Room Location :", "")}</span>
											{/* {row.email && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.email)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								) : null}
								{gridColumnPermissions.isDisplayPhone && (
									<>
										<div>
											<span onClick={() => handleOnCall(row.phone)}>
												<img src={PhoneSecondaryIcon} alt="call" />
											</span>
											<Typography variant="h6">
												Patient: &nbsp;
												<span>
													{row.phone?.trim() !== "" && (
														<Box sx={{ display: "flex" }}>
															<span>
																<a
																	onClick={(e) =>
																		handleOnClickCopy(e, row.phone)
																	}
																>
																	{row.phone}
																</a>
															</span>
															{/* {row.phone && (
																<IconButton
																	className="copy-btn"
																	onClick={(e) =>
																		handleOnClickCopy(e, row.phone)
																	}
																>
																	<img src={CopyIcon} alt="copy" />
																</IconButton>
															)} */}
														</Box>
													)}
												</span>
											</Typography>
										</div>
										{row.requestorPhone &&
										row.requestTypeId !== RequestTypes.Patient &&
										row.requestorPhone?.trim() !== "" ? (
											<div>
												<span onClick={() => handleOnCall(row.requestorPhone)}>
													<img src={PhoneSecondaryIcon} alt="call" />
												</span>
												<Typography variant="h6">
													{getTypeNameByTypeId(row.requestTypeId)}: &nbsp;
													<span>
														<Box sx={{ display: "flex" }}>
															<span className="requestor-phone">
																<a
																	onClick={(e) =>
																		handleOnClickCopy(e, row.requestorPhone)
																	}
																>
																	{row.requestorPhone}
																</a>
															</span>

															{/* <IconButton
															className="copy-btn"
															onClick={(e) =>
																handleOnClickCopy(e, row.requestorPhone)
															}
															>
															<img src={CopyIcon} alt="copy" />
														</IconButton> */}
														</Box>
													</span>
												</Typography>
											</div>
										) : null}
									</>
								)}

								{gridColumnPermissions.isDisplayRequestStatus &&
									userRole.userRoleId == AccountType.Admin && (
										<div>
											<span>
												<img src={StatusCheckSecondaryIcon} alt="check" />
											</span>
											<Typography variant="h6">
												Status: &nbsp; <span>{row.requestStatus}</span>
												{/* {row.requestStatus && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.requestStatus)
													}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
											</Typography>
										</div>
									)}

								{gridColumnPermissions.isDisplayTransferNotes &&
									userRole.userRoleId == AccountType.Admin &&
									row.transferNotes !== "" && (
										<div>
											<span>
												<img src={NotesIcon} alt="check" />
											</span>
											<Typography variant="h6">
												Transfer Notes: &nbsp; <span>{row.transferNotes}</span>
											</Typography>
										</div>
									)}
								{gridColumnPermissions.isDisplayAcceptedDate && (
									<div>
										<span>
											<img src={CalendarSecondaryIcon} alt="calender" />
										</span>
										<Typography variant="h6">
											Date of service: &nbsp;
											{row.requestStatusId == RequestStatus.Cancelled ||
											row.requestStatusId == RequestStatus.CancelledByPatient ||
											row.requestStatusId ==
												RequestStatus.CancelledByProvider ? (
												<span>{row.requestedDate ?? "-"}</span>
											) : (
												<span>{row.acceptedDate ?? "-"}</span>
											)}
											{/* {row.acceptedDate && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.acceptedDate)
													}
												>
													<img src={CopyIcon} alt="copy" />
											    </IconButton>
											)} */}
										</Typography>
									</div>
								)}

								{gridColumnPermissions.isDisplayPhysicianName && (
									<div>
										<span>
											<img src={PhysicianSecondaryIcon} alt="check" />
										</span>
										<Typography variant="h6">
											Physician: &nbsp;
											<span>{row.physicianName}</span>
											{/* {row.physicianName && (
												<IconButton
													className="copy-btn"
													onClick={(e) =>
														handleOnClickCopy(e, row.physicianName)
													}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayRequestorName && (
									<div>
										<span>
											<img src={PatientSecondaryIcon} alt="check" />
										</span>
										<Typography variant="h6">
											Requestor: &nbsp;
											<span>{row.requestor}</span>
											{/* {row.requestor && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.requestor)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								)}
								{gridColumnPermissions.isDisplayRegion && (
									<div>
										<span>
											<img src={RegionIcon} alt="check" />
										</span>
										<Typography variant="h6">
											Region: &nbsp; <span>{row.regionName}</span>
											{/* {row.regionName && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.regionName)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
										</Typography>
									</div>
								)}

								<div>
									<span>
										<img src={PatientSecondaryIcon} alt="check" />
									</span>
									<Typography variant="h6">
										Patient Background: &nbsp;{" "}
										<a
											href={`http://www.google.com/search?q=${row.name} ${row.phone}`}
											target="_blank"
											rel="noreferrer"
										>
											{row.name}
										</a>
										{/* {row.regionName && (
												<IconButton
													className="copy-btn"
													onClick={(e) => handleOnClickCopy(e, row.regionName)}
												>
													<img src={CopyIcon} alt="copy" />
												</IconButton>
											)} */}
									</Typography>
								</div>

								<Button
									className={`menu-link-btn btn-case`}
									onClick={() => {
										tokenManager.setYOffset(window.scrollY);
										navigate(
											AppRoutings.ViewCase.replace(":caseId", row.queryString),
											{
												state: {
													backURL: AppRoutings.Dashboard.replace(
														":tabStatus",
														getTabStatusWiseName(
															searchCriteria?.RequestStatusId
														).toLocaleLowerCase()
													),
													searchCriteria,
												},
											}
										);
									}}
									variant="outlined"
								>
									<Typography>View Case</Typography>
								</Button>

								<Box className="table-menu-btn-group">
									{gridButtonsPermissions.isAssignCase ? (
										<Button
											className="menu-link-btn bg-btn-assign"
											onClick={handleAssignCaseModalOpen}
											variant="outlined"
										>
											Assign Case
										</Button>
									) : null}
									{gridButtonsPermissions.isAccept ? (
										<Button
											className="menu-link-btn bg-btn-assign"
											onClick={handleAcceptCaseModalOpen}
											variant="outlined"
										>
											Accept
										</Button>
									) : null}
									{(gridButtonsPermissions.isCancelCase &&
										(searchCriteria.RequestStatusId !==
											InformationTabTypes.Closed ||
											row.requestStatusId === RequestStatus.Cancelled ||
											row.requestStatusId ===
												RequestStatus.CancelledByProvider ||
											row.requestStatusId ===
												RequestStatus.CancelledByPatient)) ||
									gridButtonsPermissions.isReject ? (
										<Button
											className="menu-link-btn bg-btn-block"
											onClick={handleCancelCaseModalOpen}
											variant="outlined"
										>
											{rejectCase}
										</Button>
									) : null}
									{gridButtonsPermissions.isSendBusyNotification && (
										<Button
											className="menu-link-btn bg-btn-upload"
											onClick={handleSendDefaultNotificationModalOpen}
											variant="outlined"
										>
											Send Busy Notification
										</Button>
									)}
									{gridButtonsPermissions.isSendAgreement && (
										<Button
											className="menu-link-btn bg-btn-upload"
											onClick={handleSendAgreemenrModalOpen}
											variant="outlined"
										>
											Send Agreement
										</Button>
									)}
									{gridButtonsPermissions.isSkipAgreement &&
										(row.requestTypeId === RequestTypes.Business ||
											row.requestTypeId === RequestTypes.Concierge) && (
											<Button
												className="menu-link-btn bg-btn-notes"
												onClick={handleClickSkipAgreement}
												variant="outlined"
											>
												Skip Agreement
											</Button>
										)}
									{gridButtonsPermissions.isCloseCase ? (
										<Button
											className="menu-link-btn bg-btn-block"
											onClick={() => {
												navigate(
													AppRoutings.CloseCase.replace(
														":caseId",
														row.queryString
													),
													{
														state: {
															backURL: AppRoutings.Dashboard.replace(
																":tabStatus",
																getTabStatusWiseName(
																	searchCriteria?.RequestStatusId
																).toLocaleLowerCase()
															),
															searchCriteria,
														},
													}
												);
											}}
											variant="outlined"
										>
											Close Case
										</Button>
									) : null}
									{gridButtonsPermissions.isConcludeCare ? (
										<Button
											className="menu-link-btn bg-btn-block"
											onClick={() => {
												navigate(
													AppRoutings.ConcludeCare.replace(
														":caseId",
														row.queryString
													),
													{
														state: {
															searchCriteria,
															backURL: AppRoutings.Dashboard.replace(
																":tabStatus",
																getTabStatusWiseName(
																	searchCriteria?.RequestStatusId
																).toLocaleLowerCase()
															),
														},
													}
												);
											}}
											variant="outlined"
										>
											Conclude Care
										</Button>
									) : null}
									{gridButtonsPermissions.isViewNotes ? (
										<Button
											className="menu-link-btn bg-btn-notes"
											onClick={() => {
												navigate(
													AppRoutings.ViewNotes.replace(
														":caseId",
														row.queryString
													),
													{
														state: {
															searchCriteria,
															backURL: AppRoutings.Dashboard.replace(
																":tabStatus",
																getTabStatusWiseName(
																	searchCriteria?.RequestStatusId
																).toLocaleLowerCase()
															),
														},
													}
												);
											}}
											variant="outlined"
										>
											View Notes
										</Button>
									) : null}

									{gridButtonsPermissions.isTransfer ? (
										<Button
											className="menu-link-btn bg-btn-cancel"
											onClick={handleTransferCaseModalOpen}
											variant="outlined"
										>
											Transfer
										</Button>
									) : null}
									{gridButtonsPermissions.isBlockCase ? (
										<Button
											className="menu-link-btn bg-btn-block"
											onClick={handleBlockCaseModalOpen}
											variant="outlined"
										>
											Block Patient
										</Button>
									) : null}
									{gridButtonsPermissions.isDoctorNotes ? (
										<Button
											className="menu-link-btn bg-btn-doctors"
											onClick={() => {
												if (
													row.finalizedRecommendationFormName == "" ||
													userRole.userRoleId == AccountType.Admin
												) {
													navigate(
														AppRoutings.DoctorsNote.replace(
															":caseId",
															row.queryString
														),
														{
															state: {
																searchCriteria,
																backURL: AppRoutings.Dashboard.replace(
																	":tabStatus",
																	getTabStatusWiseName(
																		searchCriteria?.RequestStatusId
																	).toLocaleLowerCase()
																),
															},
														}
													);
												} else {
													handleAlreadyFinalizedModalOpen(2);
												}
											}}
											variant="outlined"
										>
											Doctors Note
										</Button>
									) : null}
									{gridButtonsPermissions.isViewUploads ? (
										<Button
											className="menu-link-btn bg-btn-notes"
											onClick={() => {
												navigate(
													AppRoutings.ViewUploads.replace(
														":caseId",
														row.queryString
													),
													{
														state: {
															backURL: AppRoutings.Dashboard.replace(
																":tabStatus",
																getTabStatusWiseName(
																	searchCriteria?.RequestStatusId
																).toLocaleLowerCase()
															),
															searchCriteria,
														},
													}
												);
											}}
											variant="outlined"
										>
											View Uploads
										</Button>
									) : null}
									{gridButtonsPermissions.isEncounter ? (
										<Button
											className="menu-link-btn bg-btn-encounter"
											onClick={() => {
												// 	navigate(
												// 		AppRoutings.Encounter.replace(
												// 			":caseId",
												// 			row.queryString
												// 		),
												// 		{
												// 			state: {
												// 				backURL: AppRoutings.Dashboard.replace(
												// 					":tabStatus",
												// 					getTabStatusWiseName(
												// 						searchCriteria?.RequestStatusId
												// 					).toLocaleLowerCase()
												// 				),
												// 				searchCriteria,
												// 			},
												// 		}
												// 	);
												// }
												if (
													row.isHouseCallOrCounsult != null ||
													(row.isHouseCallOrCounsult == null &&
														searchCriteria.RequestStatusId !=
															InformationTabTypes.Active) ||
													userRole.userRoleId == AccountType.Admin
												) {
													if (
														row.finalizedEncounterFileName == "" ||
														userRole.userRoleId == AccountType.Admin
													) {
														navigate(
															AppRoutings.Encounter.replace(
																":caseId",
																row.queryString
															),
															{
																state: {
																	searchCriteria,
																	backURL: AppRoutings.Dashboard.replace(
																		":tabStatus",
																		getTabStatusWiseName(
																			searchCriteria?.RequestStatusId
																		).toLocaleLowerCase()
																	),
																},
															}
														);
													} else {
														handleAlreadyFinalizedModalOpen(1);
													}
												} else {
													handleSelectCallTypeOpen();
												}
											}}
											variant="outlined"
										>
											Encounter
										</Button>
									) : null}

									{gridButtonsPermissions.isOrders &&
									searchCriteria.RequestStatusId != 4 &&
									searchCriteria.RequestStatusId != 2 ? (
										<Button
											className="menu-link-btn bg-btn-upload"
											onClick={() => {
												navigate(
													AppRoutings.Orders.replace(
														":caseId",
														row.queryString
													),
													{
														state: {
															backURL: AppRoutings.Dashboard.replace(
																":tabStatus",
																getTabStatusWiseName(
																	searchCriteria?.RequestStatusId
																).toLocaleLowerCase()
															),
															searchCriteria,
														},
													}
												);
											}}
											variant="outlined"
										>
											Orders
										</Button>
									) : null}
									{gridButtonsPermissions.isClear &&
									row.requestStatusId !== RequestStatus.Cancelled &&
									row.requestStatusId !== RequestStatus.CancelledByProvider &&
									row.requestStatusId !== RequestStatus.CancelledByPatient ? (
										<Button
											className="menu-link-btn bg-btn-upload"
											variant="outlined"
											onClick={handleClearCaseModalOpen}
										>
											Clear Case
										</Button>
									) : null}
									{row.requestStatusId == RequestStatus.MDOnSite &&
									userRole.userRoleId === AccountType.Physician ? (
										<Button
											className="menu-link-btn"
											onClick={handleEndHouseCall}
											variant="contained"
										>
											House call
										</Button>
									) : null}
									{row.email && row.email !== "" ? (
										<Button
											className="menu-link-btn bg-btn-notes"
											variant="outlined"
											onClick={handleSendMailOpen}
										>
											Email
										</Button>
									) : null}
									{/* {row.phone && row.phone !== "" && (
										<Button
											className="menu-link-btn bg-btn-upload"
											variant="outlined"
											onClick={handleOnCall}
										>
											Call
										</Button>
									)} */}
								</Box>
								<Box
									className="table-cell-btn-group"
									sx={{ alignItems: "center" }}
								>
									<label style={{ marginRight: "10px" }}>Chat with:</label>
									{row.requestStatusId !== RequestStatus.Unassigned &&
										gridColumnPermissions.isDisplayChatWithPatient && (
											<Tooltip title="Chat with Patient">
												<Button
													variant="outlined"
													onClick={() =>
														handleOpenChatBox(
															row.userId,
															row.requestId,
															row.physicianId,
															row.physicianName,
															"Admin",
															row.confirmationNumber,
															true
														)
													}
												>
													<img src={PatientPrimaryIcon} alt="patient" />
													Patient
												</Button>
											</Tooltip>
										)}
									{gridColumnPermissions.isDisplayChatWithPhysician && (
										<Tooltip title="Chat with Provider">
											<Button
												disabled={!row.physicianId}
												variant="outlined"
												onClick={() =>
													handleOpenChatBox(
														row.userId,
														row.requestId,
														row.physicianId,
														row.physicianName,
														"Admin",
														row.confirmationNumber,
														false
													)
												}
											>
												<img src={DoctorPrimaryIcon} alt="physician" />
												Provider
											</Button>
										</Tooltip>
									)}
									{gridColumnPermissions?.isDisplayChatWithAdmin && (
										<Tooltip title="Chat with Admin">
											<Button
												disabled={!row.physicianId}
												variant="outlined"
												onClick={() =>
													handleOpenChatBox(
														row.userId,
														row.requestId,
														row.physicianId,
														row.physicianName,
														"Admin",
														row.confirmationNumber,
														false,
														true
													)
												}
											>
												<img src={DoctorPrimaryIcon} alt="" />
												Admin
											</Button>
										</Tooltip>
									)}
									{/* {row.requestStatusId !== RequestStatus.Unassigned &&
										row.requestStatusId !== RequestStatus.Accepted && (
											<Tooltip title="Chat on WhatsApp">
												<Button
													variant="outlined"
													onClick={handleClickWhatsApp}
												>
													<WhatsApp />
												</Button>
											</Tooltip>
										)} */}
								</Box>
							</CardContent>
						</Collapse>
					</div>
				</Card>
			)}
			<DashboardTableRowMenu
				row={row}
				tableMenuOpen={tableMenuOpen}
				tableUser={tableUser}
				handleTableMenuClose={handleTableMenuClose}
				handleBlockCaseModalOpen={handleBlockCaseModalOpen}
				handleAssignCaseModalOpen={handleAssignCaseModalOpen}
				handleTransferCaseModalOpen={handleTransferCaseModalOpen}
				handleAcceptCaseModalOpen={handleAcceptCaseModalOpen}
				handleCancelCaseModalOpen={handleCancelCaseModalOpen}
				handleClearCaseModalOpen={handleClearCaseModalOpen}
				handleSendAgreemenrModalOpen={handleSendAgreemenrModalOpen}
				handleSelectCallTypeModalOpen={handleSelectCallTypeOpen}
				handleAlreadyFinalizedModalOpen={handleAlreadyFinalizedModalOpen}
				handleClickSkipAgreement={handleClickSkipAgreement}
				handleSendDefaultNotificationModalOpen={
					handleSendDefaultNotificationModalOpen
				}
				gridButtonsPermissions={gridButtonsPermissions}
				searchCriteria={searchCriteria}
			/>

			{/* Page modals */}
			<SendEmailModal
				handleSendEmailModalClose={handleSendEmailModalClose}
				sendMailOpen={sendMailOpen}
				row={row}
			/>
			<BlockCaseModal
				handleBlockCaseModalClose={handleBlockCaseModalClose}
				blockCaseModelOpen={blockCaseModelOpen}
				row={row}
			/>
			{/* Assign Case Modal */}
			<AssignTransferCaseModal
				assignTransferCaseModalClose={assignCaseModalClose}
				assignTransferCaseModalOpen={assignCaseModalOpen}
				isTransfer={false}
				queryString={row?.queryString || ""}
				requestId={row?.requestId || 0}
				requestStatus={row?.requestStatusId || 0}
				regionId={row?.regionId}
			/>
			{/* Transfer Case Modal */}
			<AssignTransferCaseModal
				assignTransferCaseModalClose={transferCaseModalClose}
				assignTransferCaseModalOpen={transferCaseModalOpen}
				isTransfer={true}
				queryString={row?.queryString || ""}
				requestId={row?.requestId || 0}
				requestStatus={row?.requestStatusId || 0}
				regionId={row?.regionId}
			/>
			<CancelCaseModal
				cancelCaseModalClose={cancelCaseModalClose}
				cancelCaseModalOpen={cancelCaseModalOpen}
				queryString={row.queryString}
				requestId={row.requestId}
				requestStatus={searchCriteria.RequestStatusId || 0}
				// requestStatus={row?.requestStatusId || 0}
			/>
			<WarningModal
				isModalOpen={clearCaseModalOpen}
				handleOnClickCloseModal={handleOnClickCloseClearCase}
				handleOnClickOk={handleOnClickClearCase}
				warningMessage={
					"Are sure you want to clear this request? Once clear this request then you are not able to see this request."
				}
				title={"Confirmation for clear case"}
				okButtonText={"Clear"}
				closeButtonText={"Cancel"}
			/>
			<AcceptCaseModal
				isModalOpen={accpetCaseModalOpen}
				handleAccpetCaseModalClose={handleAccpetCaseModalClose}
				queryString={row.queryString}
			/>
			<SendAgreementModal
				row={row}
				isSendAgreementModalOpen={sendAgreementModalOpen}
				sendAgreementModalClose={sendAgreementModalClose}
				handleOnClickSend={handleOnClickSendAgreement}
			/>
			<SendDefaultMessageModal
				row={row}
				isSendDefaultMessageModalOpen={sendDefaultMessageModalOpen}
				SendDefaultMessageModalClose={SendDefaultMessageModalClose}
				handleOnClickSend={handleOnClickSendMessage}
			/>
			<SelectCallTypeModal
				isSelectCallTypeModalOpen={selectCallTypeModalOpen}
				selectCallTypeModalClose={handleSelectCallTypeClose}
				handleOnClickSave={handleOnClickCallType}
			/>
			<AlreadyFinalizedModal
				isAlreadyFinalizedModalOpen={alreadyFinalizedModalOpen}
				alreadyFinalizedModalClose={handleAlreadyFinalizedModalClose}
				handleOnClickDownload={handleOnClickDownload}
				handleOnClickSend={handleEncounterSendEmail}
				values={docTypeValues}
				handleClickRegenarate={handleClickRegenerate}
			/>

			<SendDocumetModal
				isSendDocumentModalOpen={sendRecommendationEmailModalOpen}
				sendDocumentModalClose={handleSendRecommedationModalClose}
				onSetEmail={(email: string) => {
					setEmail(email);
				}}
				handleOnClickSend={handleOnClickSendRecommedation}
				values={{ email: row.email }}
			/>

			<WarningModal
				isModalOpen={regenerateConfirmationModalOpen}
				handleOnClickCloseModal={handleCloseRegerateModal}
				handleOnClickOk={handleConfirmRegenerate}
				warningMessage={
					"Are you sure you want to Re-generate this form again?\nIf yes than the old form will be replaced by the new one"
				}
				title={"Confirmation for re-generate recommedation form"}
				okButtonText={"Ok"}
				closeButtonText={"Cancel"}
			></WarningModal>

			<ViewMoreModal
				isOpen={isTransferNotesModalOpen}
				handleClose={handleTransferNotesModalClose}
				notes={row.transferNotes}
				seperator={"\n"}
				title="Transfer Notes"
			/>

			<WarningModal
				isModalOpen={isAppOpenInfoModalOpen}
				handleOnClickOk={() => {
					var fallbackUrl =
						"https://play.google.com/store/apps/details?id=com.glip.mobile";
					if (isIOS()) {
						fallbackUrl =
							"https://apps.apple.com/in/app/ringcentral/id715886894";
					}

					window.location.assign(fallbackUrl);
				}}
				handleOnClickCloseModal={() => setIsAppOpenInfoModalOpen(false)}
				okButtonText="Install"
				closeButtonText="Cancel"
				title="Redirecting..."
				warningMessage="If RingCentral App is installed it will redirect you automatically, if not installed please install the app by clicking Install."
			/>

			{/* WhatsApp Popover */}

			<Popover
				open={isWhatsAppPopoverOpen}
				anchorEl={anchorEl}
				sx={{ borderRadius: "10px !important" }}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				onClose={() => {
					setIsWhatsAppPopoverOpen(false);
					setAnchorEl(null);
				}}
			>
				{isWhatsAppPopoverOpen && (
					<WhatsAppChatBox
						requestId={row.requestId}
						clientName={row.name}
						phoneNumber={row.phone}
						initiateTime={chatInitiateTime}
					/>
				)}
			</Popover>

			<div id="downloadBtn"></div>
		</>
	);
}
