import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { CloseWhiteIcon } from "assests/images";
import React from "react";
import { commonModalStyle } from "utility/helpers";

type ComponentProps = {
	isModelOpen: boolean;
	handleModalClose: any;
	url: string;
};

export function PreviewFileModal(props: ComponentProps) {
	// Extract Props
	const { isModelOpen, handleModalClose, url } = props;

	// handle events and functions
	const isImageUrl = (url: string) => {
		const regex = /\.(jpeg|jpg|png|webp|heif|avif|gif|svg)/i;
		return regex.test(url);
	};

	return (
		<>
			<Modal
				open={isModelOpen}
				onClose={handleModalClose}
				className="send-modal"
			>
				<Box sx={commonModalStyle}>
					<Box className="modal-header">
						<Typography variant="h5">Preview</Typography>
						<IconButton onClick={handleModalClose}>
							<img src={CloseWhiteIcon} alt="close" />
						</IconButton>
					</Box>
					<Box className="modal-body">
						{isImageUrl(url) ? (
							<div className="modal-image scrollable-modal">
								<img className="" src={url} alt="Patient Image" />
							</div>
						) : (
							<div>
								<iframe
									className="w-100 h-500px"
									title="Preview"
									// src={url}
									src={`https://docs.google.com/gview?v=${new Date().getTime()}&url=${encodeURIComponent(
										url.replace(" ", "%20")
									)}&embedded=true`}
								/>
							</div>
						)}
						<div className="modal-footer">
							<a
								href={url}
								target="_blank"
								rel="noreferrer"
								className="link-text"
							>
								If you can not preview, click here
							</a>
							<Button variant="outlined" onClick={handleModalClose}>
								Close
							</Button>
						</div>
					</Box>
				</Box>
			</Modal>
		</>
	);
}
